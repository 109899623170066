import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const Thanks = () => {
  return (
    <Layout>
      <SEO title="Thanks!" />
      <main className="error-page">
        <div className="error-container">
          <div className="container">
            <h1>:)</h1>
            <h2>Thank you for sending me an email!</h2>
            <p>I will get back to you as soon as I can.</p>
            <br />
            <Link to="/" className="btn">
              back home
            </Link>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Thanks;
